<template lang="pug">
  v-card
    v-card-title.text-center.display-1.grey--text COMPLETE
        v-spacer
            v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
    v-card-text.text-center
        v-row.mx-auto(justify="space-around").text-center
            v-col(cols="12")
                v-avatar(size="130")
                    v-icon(color="success" size="64" v-text="'mdi-check'")
                h3.font-weight-light
                    | THANK YOU
                h3.font-weight-light
                    | your account setup has been completed
            h2.text-center.display-2.grey--text SHARE THIS APP WITH YOUR FRIENDS
            v-col.text-center
                ShareNetwork(
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :url="sharing.url"
                    :title="sharing.title"
                    :description="sharing.description"
                    :quote="sharing.quote"
                    :hashtags="sharing.hashtags"
                    ).mx-2
                    v-icon(size="40" :color="network.name === 'Facebook' ? 'secondary':'green'") {{network.icon}}
                    // span(color="white") {{ network.name }}
            v-col(cols="12").text-center
                v-btn(color="primary" rounded max-width="100" @click="$emit('close')") OK
</template>

<script>
  export default {

    data () {
      return {
        card: '',
        loading: false,
        snackbar: {
          color: 'red',
          snackbar: false,
          text: '',
          timeout: 2000,
        },
      }
    },
    computed: {
      sharing () {
        return {
          url: 'https://scenefinder.com/',
          title: 'Scene Finder',
          description: 'A new way to easily CAPTURE, SHARE and BE REWARDED for your evidence-base content.',
          quote: 'Hey, i just downloaded this cool app, called Scene Finder that pays me cash rewards, take a look',
          hashtags: 'SceneFinder',
        }
      },
      networks () {
        return [
          { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: '#1877f2' },
          { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' },
        ]
      },
    },
  }
</script>
